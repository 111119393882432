@import "../../helpers/_variables.scss";
@import "../../helpers/_functions.scss";
@import "./layout";

.portfolio-card {
    @include card;
    border-color: $color-accent-1;
    animation: fade-in__right forwards 1.5s 0.4s;
    margin-right: $margin-small;
    h2 {
        color: $color-accent-1;
    }
}
