@import "../../helpers/_variables.scss";
@import "../../helpers/_functions.scss";
@import "./layout";

.other-project-card {
    @include card;
    opacity: 1;
    font-size: $font-size-medium;
    padding: $padding-medium;

    &__infos {
        .project-name {
            text-transform: uppercase;
            font-weight: $font-weight-medium;
            font-size: $font-size-xx-large;
            line-height: 1.2;
        }

        .project-role {
            color: $color-primary;
            font-weight: $font-weight-regular;
            font-size: $font-size-large;
            margin-bottom: $margin-xx-small;
            line-height: 1.2;
        }
    }

    &__image {
        flex: 1;
    }
}

//colors
.other-project-card {
    &.color-project-1 {
        border-color: $color-project-1;

        .project-name {
            color: $color-project-1;
        }
    }

    &.color-project-2 {
        border-color: $color-project-2;

        .project-name {
            color: $color-project-2;
        }
    }

    &.color-project-3 {
        border-color: $color-project-3;

        .project-name {
            color: $color-project-3;
        }
    }
}