@import "../helpers/_variables.scss";
@import "../helpers/_functions.scss";

.others-projects-list {
   display: grid;
   grid-template-columns: repeat(3, minmax(0, 1fr));
   gap: 2rem;
   padding: 1rem;
   padding-top: 0;
   width: 100%;
   max-width: $max-width;
}

//------------------------------------------------------
//--------------------- RESPONSIVE ---------------------
//------------------------------------------------------

@media screen and (max-width: px(1200)) {
   .others-projects-list {
      grid-template-columns: repeat(2, minmax(0, 1fr));
   }
}

@media screen and (max-width: px(700)) {
   .others-projects-list {
      grid-template-columns: repeat(1, minmax(0, 1fr));
   }
}