@import "../helpers/_variables.scss";
@import "../helpers/_functions.scss";

.navigator-preview {
    overflow: hidden;

    &__nav {
        background-color: $color-text-grey;
        padding: $padding-xx-small $padding-x-small;

        &__dot {
            width: px(10);
            height: px(10);
            border-radius: $border-radius-round;

            &.dot-red {
                background-color: $color-danger;
            }

            &.dot-orange {
                background-color: $color-warning;
                margin: 0 $margin-xx-small;
            }

            &.dot-green {
                background-color: $color-success;
            }
        }
    }

    &__content {
        position: relative;
        width: 100%;
        /* 16:9 Aspect Ratio */
        padding-top: 56.25%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }
}