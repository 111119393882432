@import "../../helpers/_variables.scss";
@import "../../helpers/_functions.scss";
@import "./layout";

.cv-card {
    @include card;
    border-color: $color-accent-2;
    animation: fade-in__right forwards 1.5s 0.6s;
    h2 {
        color: $color-accent-2;
    }
}
