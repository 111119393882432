@import "../helpers/_variables.scss";
@import "../helpers/_functions.scss";

//Generals
.button-icon {
    border-radius: $border-radius-round;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        cursor: pointer;
    }

    &__icon {
        width: 100%;
    }
}

//Colors and appearance manager
.button-icon {
    &[_appearance="fill"] {
        background-color: transparent;

        &[_color="primary"] {
            background-color: $color-primary;
        }

        &[_color="secondary"] {
            background-color: $color-secondary;
        }

        &[_color="accent-1"] {
            background-color: $color-accent-1;
        }

        &[_color="accent-2"] {
            background-color: $color-accent-2;
        }

        &[_color="accent-3"] {
            background-color: $color-accent-3;
        }

        //projects
        &[_color="color-project-1"] {
            background-color: $color-project-1;
        }

        &[_color="color-project-2"] {
            background-color: $color-project-2;
        }

        &[_color="color-project-3"] {
            background-color: $color-project-3;
        }
    }
}

//Size manager
.button-icon {
    &[_size="regular"] {
        width: rem(2.5);
        min-width: rem(2.5);
        max-width: rem(2.5);

        height: rem(2.5);
        min-height: rem(2.5);
        max-height: rem(2.5);

        padding: rem(0.5);
    }

    &[_size="large"] {
        width: rem(3.5);
        min-width: rem(3.5);
        max-width: rem(3.5);

        height: rem(3.5);
        min-height: rem(3.5);
        max-height: rem(3.5);

        padding: rem(0.8);
    }
}
