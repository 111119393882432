@import "../../helpers/_variables.scss";
@import "../../helpers/_functions.scss";
@import "./layout";

.best-project-card {
    @include card;
    opacity: 1;
    font-size: $font-size-medium;
    padding: $padding-xxx-small;
    width: 95%;
    max-width: px(1200);
    height: px(350);

    &__infos {
        padding: $padding-medium $padding-small;

        &__head {
            .project-name {
                text-transform: uppercase;
                font-weight: $font-weight-medium;
                font-size: $font-size-xx-large;
                line-height: 1.2;
            }

            .project-role {
                color: $color-primary;
                font-weight: $font-weight-regular;
                font-size: $font-size-large;
                margin-bottom: $margin-xx-small;
                line-height: 1.2;
            }
        }

        &__body {
            overflow: auto;
            margin: $margin-small 0;

            .project-description {
                color: $color-text-grey;
                line-height: 1.5;
                white-space: pre-line;
            }
        }

        &__foot {
            &__technos {
                flex-wrap: wrap;
            }

            &__actions .action {
                margin: $margin-xxx-small;
            }
        }
    }
}

//colors
.best-project-card {
    &.color-project-1 {
        border-color: $color-project-1;

        .project-name {
            color: $color-project-1;
        }
    }

    &.color-project-2 {
        border-color: $color-project-2;

        .project-name {
            color: $color-project-2;
        }
    }

    &.color-project-3 {
        border-color: $color-project-3;

        .project-name {
            color: $color-project-3;
        }
    }
}

//------------------------------------------------------
//--------------------- RESPONSIVE ---------------------
//------------------------------------------------------

@media screen and (max-width: px(1300)) {
    .best-project-card {
        height: px(400);

        &__image {
            width: 100% !important;
            height: auto;
            padding: $padding-regular 0;
        }

        &__infos {
            width: 100% !important;
            padding: $padding-regular;
        }
    }
}

@media screen and (max-width: px(1100)) {
    .best-project-card {
        max-width: px(800);
        height: auto;
        flex-direction: column;
        padding-top: rem(5);

        &__image {
            max-width: px(600);
        }

        &__infos {
            padding-top: 0;

            &__head {
                position: absolute;
                top: $padding-regular;
            }
        }
    }
}

@media screen and (max-width: px(600)) {
    .best-project-card {
        &__infos__foot {
            flex-direction: column;

            &__technos {
                width: 100%;
                justify-content: center !important;
            }
        }
    }
}