@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fade-in__right {
    from {
        opacity: 0;
        transform: translateX(px(-15));
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes typing__with-caret {
    from {
        width: 0;
        border-color: $color-primary;
    }
    90%{
        border-color: $color-primary;
    }
    95%{
        border-color: transparent;
    }
    to {
        width: 105%;
    }
}

@keyframes typing-caret {
    from {
        border-color: $color-primary;
    }
    50%{
        border-color: $color-primary;
    }
    51%{
        border-color: transparent;
    }
    to {
        border-color: transparent;
    }
}
